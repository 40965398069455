import { createBrowserRouter, Link } from "react-router-dom";
import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import Notification from '../notifications';
import { notification } from 'antd';
import APIClient from "../api";


const SignUpForm = ({setUser}) => {
    const [notifications, contextHolder] = notification.useNotification();

    const onFinish = async (values) => {
      const result = await APIClient.register(values)
      if(result.error){
        notifications.error(
          {
            message: "Error",
            description: result.error,
            placement: "bottomRight"
          },
        );
      } else if(result.user){
        setUser(result.user)
      }
    };
    const onFinishFailed = (errorInfo) => {
      notifications.error(
        {
          message: "Error",
          description: "Sign up form is incomplete",
          placement: "bottomRight"
        },
      );
    };

    return (
        <div>
            {contextHolder}
            <h1>Sign up</h1>
            <p>Enter your credentials to sign up</p>

            <Form
                name="signup"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}

                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input your name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{
                        required: true,
                        type: "email",
                        message: 'Please input your email!'
                    }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="Confirm Password"
                    name="passwordConfirmation"
                    rules={[{ required: true, message: 'Please confirm your password!' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            <div>
                Already have an account? <Link to="/login">Log in</Link>
            </div>
        </div>
    )
};

export default SignUpForm