const APIClient = {
    API_ENDPOINT: 'https://staging.kubexplain.com/api/v1',
    headers: () => {
        let headers = {
            'Content-Type': 'application/json',
        }

        if(localStorage.getItem('token')){
            headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
        }

        return headers
    },

    buildUrl: (endpoint) => {
        return `${APIClient.API_ENDPOINT}${endpoint}`
    },
    get: async (endpoint) => {
        const response = await fetch(APIClient.buildUrl(endpoint),{headers: APIClient.headers()})
        return await response.json()
    },

    post: async (endpoint, data) => {
        const response = await fetch(APIClient.buildUrl(endpoint), {
            method: 'POST',
            body: JSON.stringify(data),
            headers: APIClient.headers()
        })

        return await response.json()
    },

    put: async (endpoint, data) => {
        const response = await fetch(APIClient.buildUrl(endpoint), {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: APIClient.headers()
        })

        return await response.json()
    },

    delete: async (endpoint) => {
        const response = fetch(APIClient.buildUrl(endpoint), {
            method: 'DELETE',
            headers: APIClient.headers()
        })

        return await response.json()
    },

    patch: async (endpoint, data) => {
        const response = await fetch(APIClient.buildUrl(endpoint), {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: APIClient.headers()
        })

        return await response.json()
    },

    register: async (data) => {
        const { name, email, password, passwordConfirmation } = data
        if (password !== passwordConfirmation) {
            return { error: 'Passwords do not match' }
        }
        const resp = await APIClient.post('/users', { name, email, password, passwordConfirmation })

        if (resp.token) {
            localStorage.setItem('token', resp.token)
        }
        return resp
    },

    getUserInfo: async () => {
        return await APIClient.get('/users/me')
    },

    authenticate: async (email, password) => {
        const resp = await APIClient.post('/users/authenticate', { email: email, password: password })
        if (resp.token) {
            localStorage.setItem('token', resp.token)
        }
        return resp
    }

}

export default APIClient;