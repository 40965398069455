import { Link, BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined
} from '@ant-design/icons';
import { Button, Breadcrumb, Layout, Menu, theme } from 'antd';

import logo from "../assets/kubexplain.png"


const { Header, Sider, Content, Footer } = Layout;

const HomePage = ({ user }) => {
  return <div>
    <h2>Home page</h2>
    <p>Hi {user.name}!</p>
  </div>
};

const ProfilePage = () => {
  return <h2>Profile page</h2>;
};

const SettingsPage = () => {
  return <h2>Settings page</h2>;
};

const AuthenticatedUI = ({ user, setUser }) => {
  const [collapsed, setCollapsed] = useState(false);


  const items1 = ['1', '2', '3'].map((key) => ({
    key,
    label: `nav ${key}`,
  }));

  const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map((icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  });



  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (

    <BrowserRouter>
      <Layout>
        <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
          <div
            style={{
              float: 'left',
              width: 120,
              height: 31,
              margin: '16px 24px 16px 0',
              background: 'rgba(255, 255, 255, 0.2)',
            }}
          />
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['2']}
            items={new Array(3).fill(null).map((_, index) => ({
              key: String(index + 1),
              label: `nav ${index + 1}`,
            }))}
          />
        </Header>
        <Content className="site-layout" style={{ padding: '0 50px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item>
          </Breadcrumb>
          <div style={{ padding: 24, minHeight: 380, background: colorBgContainer }}>


            <Routes>
              <Route path="/" element={<HomePage user={user} setUser={setUser} />} />
              <Route path="/foo" element={<SettingsPage user={user} setUser={setUser} />} />
              <Route path="/me" element={<ProfilePage user={user} setUser={setUser} />} />
            </Routes>
          </div>
        </Content>

        <Footer style={{ textAlign: 'center' }}>Ant Design ©2023 Created by Ant UED</Footer>
      </Layout>
    </BrowserRouter >
  );
};

export default AuthenticatedUI;