import React, { useEffect, useState } from 'react';
import {
  Route, Link,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import 'antd/dist/reset.css';
import './App.css';
import APIClient from './api';
import AnonymousUI from './anonymous';
import AuthenticatedUI from './authenticated';

import { Spin, Space } from 'antd';

const App = () => {
  const [user, setUser] = useState({ authenticated: undefined });

  useEffect(() => {
    const getUserInfo = async () => {
      const userInfo = await APIClient.getUserInfo();
      setUser(userInfo);
    };

    if (user.authenticated === undefined) {
      getUserInfo();
    }
  }, [user.authenticated]);

  if (user.authenticated === true){
    return <AuthenticatedUI user={user} setUser={setUser} />
  } else {
    return (
    <div className="app-body"><div className="App">
      {user.authenticated === false && <AnonymousUI setUser={setUser} />}
      {user.authenticated === undefined &&<Space direction="vertical" style={{ width: '100%' }}>
        <Space>
          <Spin size="large">
            <div className="content" />
          </Spin>
        </Space>
      </Space>}
      </div>
    </div>
    )
  }
  
};



export default App; 