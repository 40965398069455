import { createBrowserRouter, Link } from "react-router-dom";
import { Button, Form, Input, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import APIClient from "../api";

const LoginForm = ({setUser}) => {
  const [notifications, contextHolder] = notification.useNotification();

  const onFinish = async (values) => {
    const result = await APIClient.authenticate(values.email, values.password)
    console.log(result)
    if(result.error){
      notifications.error(
        {
          message: "Error",
          description: result.error,
          placement: "bottomRight"
        },
      );
    } else if(result.user){
      setUser(result.user)
    }
  };
  const onFinishFailed = (errorInfo) => {
    notifications.error(
      {
        message: "Error",
        description: "Login form is incomplete",
        placement: "bottomRight"
      },
    );
  };

  return (

    <div>
      {contextHolder}
      <h1>Sign in to continue</h1>
      <p>Please enter your login credentials below to access your account.</p>
      <Form
        name="login"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}

        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, type: "email", message: 'Please input your Email!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>

      <div>Don't have an account yet? <Link to="/signup">Sign Up</Link></div>
    </div>




  )
};

export default LoginForm;