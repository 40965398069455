import { Route, Routes, BrowserRouter } from "react-router-dom";
import React from 'react';
import LoginForm from "./login";
import SignUpForm from "./signup";
import logo from "../assets/kubexplain.png"


// AnonymousUI component, receive setUser as a prop
const AnonymousUI = ({ setUser }) => {
    return (
        <div className="App anonymous">
            <img src={logo} className="logo" alt="Logo" width="200" />
            <BrowserRouter>
                <Routes>
                <Route path="/" element={<LoginForm setUser={setUser} />} />
                <Route path="/login" element={<LoginForm setUser={setUser} />} />
                <Route path="/signup" element={<SignUpForm setUser={setUser} />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default AnonymousUI;